<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <b-alert
        variant="info"
        show
      >
        <div class="alert-body">
          <span class="ml-25"><strong>IMPORTANTE:</strong> La contraseña por defecto es el valor del campo LOGIN</span>
        </div>
      </b-alert>

      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >
        <b-form-group
          label="Perfil"
          label-for="profile"
        >
          <v-select
            id="profile"
            v-model="profileSel"
            label="name"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="profiles"
          />
        </b-form-group>
        <b-form-group
          label="DNI"
          label-for="dni"
        >
          <b-form-input
            id="dni"
            v-model="formData.dni"
            type="text"
          />
        </b-form-group>

        <b-form-group
          label="Nombre"
          label-for="firstName"
        >
          <b-form-input
            id="firstName"
            v-model="formData.firstName"
            type="text"
          />
        </b-form-group>

        <b-form-group
          label="Apellidos"
          label-for="lastName"
        >
          <b-form-input
            id="lastName"
            v-model="formData.lastName"
            type="text"
          />
        </b-form-group>

        <b-form-group
          label="Login"
          label-for="login"
        >
          <b-form-input
            id="login"
            v-model="formData.login"
            type="text"
          />
        </b-form-group>

        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="formData.email"
            type="email"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BAlert,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const formData = ref({})
    const profiles = ref([])
    const profileSel = ref()

    const resetForm = () => {
      formData.value = {}
    }

    const loadProfiles = async () => {
      profiles.value = []
      await store.dispatch('security/PROFILE_FIND_ALL', {
        limit: 200,
        query: '',
        page: 1,
        sortBy: 'name%7CASC',
        type: 'list',
      })
        .then(response => {
          if (response) {
            profiles.value = response.items
          }
        })
    }

    const saveForm = () => {
      if (profileSel.value === null || profileSel.value === '') {
        emit('error-data', { response: { data: { message: 'Debe seleccionar perfil' } } })
        return
      }
      if (formData.value.login === null || formData.value.login === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar login de usuario' } } })
        return
      }
      if (formData.value.dni == null || formData.value.dni === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar dni de usuario' } } })
        return
      }
      if (formData.value.firstName == null || formData.value.firstName === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar nombre de usuario' } } })
        return
      }
      if (formData.value.lastName == null || formData.value.lastName === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar apellidos del usuario' } } })
        return
      }
      // Validar datos antes
      formData.value.idProfile = profileSel.value.idProfile
      formData.value.idInstitution = 0

      let service = 'security/USER_CREATE'
      if (props.formType === 'edit') {
        service = 'security/USER_UPDATE'
      }
      store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-add-new-user-sidebar-active', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const toggleSidebar = async val => {
      emit('update:is-add-new-user-sidebar-active', val)
      resetForm()
      await loadProfiles()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Usuario'
      }
      if (props.formType === 'edit') {
        formData.value = props.dataEdit
        profileSel.value = profiles.value.filter(p => p.idProfile === props.dataEdit.idProfile)
        titleForm.value = 'Editar Usuario'
      }
    }

    return {
      formData,
      saveForm,
      resetForm,
      toggleSidebar,
      titleForm,
      profiles,
      profileSel,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
